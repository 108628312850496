import styled from "styled-components";
import {
  Header,
  ChartContainer,
  Table,
  InfoSection,
  Sidebar,
  MainCard,
  MiniAssetTable,
  NewTrade,
} from "../components/index";
import { Ticker } from "react-ts-tradingview-widgets";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { userContext } from "../context/userContext";
import { useMediaQuery } from "@mui/material";
import TradeHistory from "../usertables/TradeHistory";
import ProgressCard from "../components/ProgressCard";
import NewHistory from "../usertables/NewHistory";

const Dashboard = () => {
  const { account } = useContext(userContext);
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);

  const [hasTraded, setHasTraded] = useState(false);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  useEffect(() => {
    function removeTickers() {
      const ticker = document.querySelectorAll("#tradingview_widget_wrapper");
      ticker.forEach((tick) => {
        tick.lastChild.style.display = "none";
      });
    }

    removeTickers();

    return () => {
      removeTickers();
    };
  });

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Dashboard"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <PriceCardWrap>
              <PriceCards className="cards">
                <MainCard className="main" />
                {/* <div className="priceViews">
      {priceViews.map((card) => {
        return (
          <PriceView
            key={card.id}
            img={card.src}
            symbol={card.symbol}
            coin={card.name}
            price={card.price}
            convertPrice={card.convertPrice}
          />
        );
      })}
    </div> */}
                <ProgressCard className="main" />
                <div className="priceViews">
                  <MiniAssetTable title="Assets" account={account} />
                  <Link to="/assets" className="link">
                    <p className="clickable">view all assets</p>
                  </Link>
                </div>
              </PriceCards>
            </PriceCardWrap>
            <div className="ticker">
              <Ticker colorTheme="dark" isTransparent height={200}></Ticker>
            </div>
            <ChartContainer />
            <div className="history">
              <NewHistory user={user} traded={{ hasTraded, setHasTraded }} />
            </div>
            <div className="panel">
              <div className="trade">
                <NewTrade traded={{ hasTraded, setHasTraded }} />
              </div>
              <div className="info">
                <InfoSection />
              </div>
            </div>
            <div className="footer">
              <p style={{ fontWeight: "600", color: "#a3a3a3" }}>
                BlockSafe Trades Ltd. 2022
              </p>
            </div>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  overflow-x: hidden;
  display: flex;
  max-height: 100vh;
  overflow-y: auto;
  width: 100vw;
  box-sizing: border-box;
  background-color: #0e121b;
  color: white;
`;

const PriceCardWrap = styled.div`
  grid-area: cards;
  width: 100%;
  box-sizing: border-box;
`;

const PriceCards = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
  box-sizing: border-box;
  padding: 0.5rem;
  align-items: top;
  gap: 1rem;

  .main {
    box-sizing: border-box;
    overflow-y: auto;
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: auto;
  }

  .priceViews {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Main = styled.div`
  max-height: 100vh;
  /* height: 100%; */
  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  box-sizing: border-box;
  width: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 0.3fr auto 0.2fr 2.5fr 2.5fr 0.5fr;
  grid-template-areas:
    "head head head head"
    "cards cards cards side"
    "ticker ticker ticker side"
    "chart chart chart side"
    "order-history order-history order-history side"
    "footer footer footer footer";

  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-areas:
      "head head head head"
      "cards cards cards cards"
      "ticker ticker ticker ticker"
      "chart chart chart chart"
      "side side side side"
      "order-history order-history order-history order-history"
      "footer footer footer footer";
  }

  .circle {
    height: 26px;
    border-radius: 50%;
    width: 26px;
  }

  .link {
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }

  .clickable {
    font-weight: 600;
    color: #1199fa;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    text-transform: capitalize;
    box-sizing: border-box;
    width: max-content;
    margin-top: 0.8rem;
  }

  .history {
    grid-area: order-history;
    gap: 1rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.5rem;
  }

  .ticker {
    grid-area: ticker;
    width: 100%;
    pointer-events: none;
    height: 100%;
  }

  .footer {
    grid-area: footer;
    margin: 0.5rem;
    box-sizing: border-box;
    z-index: 10;
    border-radius: 10px;
    padding: 2rem;
    background-color: #131824;
  }

  .panel {
    grid-area: side;
    height: 100%;
    width: 440px;
    flex-direction: column;
    display: flex;
    overflow-y: auto;
    /* background-color: antiquewhite; */
    justify-content: space-between;
    box-sizing: border-box;

    @media screen and (max-width: 1204px) {
      overflow-y: auto;
      margin-bottom: -1.5rem;
      width: 440px;
    }

    @media screen and (max-width: 950px) {
      overflow-y: visible;
      margin-bottom: -1.5rem;
      width: 100%;
    }
  }

  /* }

  /* .info { */
  /* background-color: aquamarine; */
  /* display: flex;
    width: 100%;
  } 

  /* .panel { */
  /* grid-area: side; */
  /* display: flex; */
  /* overflow-x: auto; */
  /* flex-direction: column; */
  /* width: 100%; */
  /* justify-content: space-between; */
  /* box-sizing: border-box; */

  /* .info {
      display: flex;
    } */
  /* } */
`;

export default Dashboard;
